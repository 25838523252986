<template>
  <div v-if="isOpen" class="checkout-overlay" @click="closeCheckout"></div>
  <div class="checkout-panel" :class="{ 'panel-open': isOpen }">
    <div class="checkout-header">
      <h2>Checkout</h2>
      <button @click="closeCheckout" class="close-btn" aria-label="Close checkout">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>

    <!-- Add this section right after the checkout-header -->
    <div class="mobile-summary" v-if="isMobile">
      <div class="summary-card">
        <div class="summary-row">
          <span>Cart Total:</span>
          <span>KSH {{ formatPrice(cartTotal) }}</span>
        </div>
        <div class="summary-row">
          <span>VAT (16%):</span>
          <span>KSH {{ formatPrice(vatAmount) }}</span>
        </div>
        <div class="summary-row" v-if="shippingMethod === 'delivery'">
          <span>Delivery:</span>
          <span>KSH {{ formatPrice(deliveryCharge) }}</span>
        </div>
        <div class="summary-row total">
          <span>Total to Pay:</span>
          <span>KSH {{ formatPrice(finalTotal) }}</span>
        </div>
      </div>
    </div>

    <div class="checkout-sections">
      <!-- Form Section (3/4) -->
      <div class="form-section">
        <div class="form-container">
          <h3 class="section-title">Delivery & Payment</h3>
          
          <!-- Shipping Method Card -->
          <div class="form-card">
            <div class="card-header">
              <h4>Delivery Method</h4>
            </div>
            <div class="card-body">
              <div class="toggle-buttons">
                <button 
                  class="toggle-btn active"
                  @click="setShippingMethod('delivery')"
                >
                  <span class="toggle-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                    </svg>
                  </span>
                  <div class="toggle-content">
                    <span class="toggle-title">Delivery</span>
                    <span class="toggle-desc">Door step delivery</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <!-- Customer Details Card -->
          <div class="form-card customer-details-card">
            <div class="card-header">
              <h4>Customer Details</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="submitOrder" class="delivery-form">
                <div class="form-content">
                  <!-- Phone Number Input -->
                  <div class="form-group">
                    <label for="phone">Phone Number</label>
                    <div class="phone-input-container">
                      <span class="phone-prefix">+254</span>
                      <input 
                        type="tel" 
                        id="phone" 
                        v-model="formData.phone"
                        @input="formatPhoneNumber"
                        @keypress="validateNumberInput"
                        required
                        placeholder="7XX XXX XXXX"
                        class="form-input phone-input"
                        maxlength="9"
                        pattern="[0-9]{9}"
                      >
                    </div>
                    <span class="input-hint" :class="{ 'error': !isValidPhone && formData.phone }">
                      {{ phoneHintText }}
                    </span>
                  </div>

                  <!-- Add this after the phone input and before the address input -->
                  <div class="form-group" v-if="shippingMethod === 'delivery'">
                    <label for="region">Select Region <span class="required">*</span></label>
                    <div class="select-container">
                      <input
                        type="text"
                        id="region"
                        v-model="regionSearch"
                        @input="filterRegions"
                        @focus="showRegionDropdown = true"
                        placeholder="Search your region..."
                        class="form-input region-input"
                        :class="{ 'error': formErrors.region }"
                        required
                      >
                      <div class="select-dropdown" v-if="showRegionDropdown">
                        <div 
                          v-for="region in filteredRegions" 
                          :key="region"
                          class="select-option"
                          :class="{ 'selected': formData.region === region }"
                          @click="selectRegion(region)"
                        >
                          {{ region }}
                        </div>
                      </div>
                    </div>
                    <span class="input-hint error" v-if="formErrors.region">
                      {{ formErrors.region }}
                    </span>
                    <span class="input-hint" v-else>
                      Please select your region for delivery
                    </span>
                  </div>

                  <!-- Address Input (when delivery is selected) -->
                  <div class="form-group" v-if="shippingMethod === 'delivery'">
                    <label for="address">Street/House No. / Apt. No. / Building Floor No.</label>
                    <div class="address-input-container">
                      <input 
                        ref="addressInput"
                        type="text" 
                        id="address" 
                        v-model="formData.address"
                        :required="shippingMethod === 'delivery'"
                        placeholder="e.g. Apartment 4B, 3rd Floor, Rose Building"
                        class="form-input address-input"
                        autocomplete="off"
                      >
                      <span class="address-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </span>
                    </div>
                    <span class="input-hint">{{ addressHintText }}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- Add v-if to hide Payment Method Card when pickup is selected -->
          <div class="form-card payment-method-card" v-if="shippingMethod !== 'pickup'">
            <div class="card-header">
              <h4>Payment Method</h4>
            </div>
            <div class="card-body">
              <div class="toggle-buttons">
                <button 
                  class="toggle-btn" 
                  :class="{ active: paymentMethod === 'mpesa' }"
                  @click="setPaymentMethod('mpesa')"
                >
                  <span class="toggle-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                  <div class="toggle-content">
                    <span class="toggle-title">M-PESA</span>
                    <span class="toggle-desc">Pay now via M-PESA</span>
                  </div>
                </button>
                
                <button 
                  class="toggle-btn" 
                  :class="{ active: paymentMethod === 'cod' }"
                  @click="setPaymentMethod('cod')"
                >
                  <span class="toggle-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </span>
                  <div class="toggle-content">
                    <span class="toggle-title">Cash on Delivery</span>
                    <span class="toggle-desc">Pay when you receive</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <!-- Pickup Location Card -->
          <div class="form-card" v-if="shippingMethod === 'pickup'">
            <div class="card-header">
              <h4>Pickup Location</h4>
            </div>
            <div class="card-body">
              <div class="store-details">
                <p class="store-name">Two Buddies Liquor Store</p>
                <p class="store-address">123 Main Street, Nairobi</p>
                <p class="store-hours">Open: Mon-Sat 9AM-9PM</p>
              </div>
            </div>
          </div>

          <!-- Complete Order Button -->
          <div class="complete-order-section">
            <button 
              @click="submitOrder" 
              :disabled="!isFormValid || isSubmitting" 
              class="complete-order-btn"
              :class="{
                'is-loading': isSubmitting,
                'is-valid': isFormValid && !isSubmitting,
                'is-invalid': !isFormValid
              }"
            >
              <span class="button-content" :class="{ 'fade-out': isSubmitting }">
                Complete Order
              </span>
              <span class="loading-spinner" :class="{ 'fade-in': isSubmitting }">
                <svg class="circular" viewBox="25 25 50 50">
                  <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Summary Section (1/4) - Add !isMobile condition -->
      <div class="summary-section" v-if="!isMobile">
        <div class="order-card">
          <div class="card-header">
            <h3>Order Summary</h3>
            <div class="header-actions">
              <span class="items-count">{{ cartItems.length }} items</span>
              <button @click="printSummary" class="print-button">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="print-icon">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                </svg>
                Print
              </button>
            </div>
          </div>

          <div class="card-body">
            <div class="summary-items">
              <div v-for="item in cartItems" :key="item.id" class="summary-item">
                <div class="item-info">
                  <span class="item-name">{{ item.name }}</span>
                  <div class="item-price-qty">
                    {{ item.quantity }}X KSH {{ formatPrice(item.price) }}
                  </div>
                </div>
                <span class="item-total">KSH {{ formatPrice(item.price * item.quantity) }}</span>
              </div>
            </div>

            <div class="summary-divider"></div>

            <div class="summary-calculations">
              <div class="calc-row">
                <span>Subtotal:</span>
                <span>KSH {{ formatPrice(subtotalBeforeVat) }}</span>
              </div>
              <div class="calc-row vat">
                <span>VAT (16%):</span>
                <span>KSH {{ formatPrice(vatAmount) }}</span>
              </div>
              <div class="calc-row" v-if="shippingMethod === 'delivery'">
                <span>Delivery Fee:</span>
                <span>KSH {{ formatPrice(deliveryCharge) }}</span>
              </div>
              <div class="calc-row total">
                <span>Total Amount:</span>
                <span>KSH {{ formatPrice(finalTotal) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SuccessNotification ref="notification" />
    
  <PaymentModal 
      ref="paymentModal"
      :amount="0"
      :orderId="''"
      :orderSerial="''"
      :phone="formData.phone"
      @timeout="handlePaymentTimeout"
      @close="handlePaymentModalClose"
      @retry="submitOrder"
    />

</template>

<script>
import { auth } from '@/services/auth';
import SuccessNotification from './SuccessNotification.vue';
import PaymentModal from './PaymentModal.vue';
import SHA256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import myBeerLogo from '@/assets/images/mybeer-logo.png' // Adjust path as needed
import { regions } from '@/data/regions'; 
import { deliveryCharges } from '@/services/deliveryCharges';

export default {
  name: 'CheckoutPanel',
  components: {
    SuccessNotification,
    PaymentModal
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    cartItems: {
      type: Array,
      default: () => []
    },
    cartTotal: {
      type: Number,
      default: 0
    }
  },
  data() {
    const storedData = this.getStoredFormData();
    return {
      formData: storedData || {
        phone: '',
        address: '',
        latitude: null,
        longitude: null,
        deliveryType: 'delivery',
        region: ''
      },
      shippingMethod: 'delivery',
      shippingCharge: 200,
      discount: 0,
      isValidPhone: true,
      autocomplete: null,
      isAddressValid: true,
      isGoogleLoaded: false,
      isPrinting: false,
      isSubmitting: false,
      currentOrderId: null,
      currentOrderSerial: null,
      lastOrderHash: null,
      isPickup: false,
      isMobile: false,
      paymentMethod: 'mpesa', // Add this new property
      regionSearch: storedData?.region || '', // Initialize regionSearch with stored region
      showRegionDropdown: false,
      regions, // Use imported regions
      formErrors: {
        region: ''
      },
    }
  },
  computed: {
    totalItems() {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0)
    },
    formattedTotal() {
      return this.formatPrice(this.cartTotal || 0)
    },
    isFormValid() {
      if (this.shippingMethod === 'delivery') {
        // Check region and other delivery requirements without side effects
        return this.isValidRegion && 
               this.isValidPhone && 
               this.formData.address;
      }
      // For pickup, only check phone
      return this.isValidPhone;
    },
    subtotalBeforeVat() {
      // Calculate subtotal excluding VAT (divide by 1.16 to get pre-VAT amount)
      return this.cartTotal;
    },
    vatAmount() {
      // Calculate VAT (16% of the total)
      return this.cartTotal * 0.16;
    },
    finalTotal() {
      // Total excluding VAT plus delivery if applicable
      return this.subtotalBeforeVat + (this.shippingMethod === 'delivery' ? this.deliveryCharge : 0);
    },
    phoneHintText() {
      if (!this.formData.phone) {
        return 'Enter your mpesa phone number'
      }
      if (!this.isValidPhone) {
        return 'Please enter a valid phone number'
      }
      return ''
    },
    addressHintText() {
      if (!this.formData.address) {
        return 'Search and select your delivery address'
      }
      return this.isAddressValid ? 'Valid delivery address' : 'Please select an address from the suggestions'
    },
    totalItemCount() {
      return this.cartItems.reduce((sum, item) => sum + item.quantity, 0);
    },
    orderSummary() {
      return {
        subtotal: this.subtotal,
        tax: this.tax,
        shipping: this.shippingCharge,
        total: this.finalTotal,
        itemCount: this.totalItemCount
      };
    },
    deliveryCharge() {
      return deliveryCharges.calculateDeliveryCharge(
        this.formData.region,
        this.cartTotal,
        this.shippingMethod
      );
    },
    filteredRegions() {
      if (!this.regionSearch) return this.regions;
      const search = this.regionSearch.toLowerCase();
      return this.regions.filter(region => 
        region.toLowerCase().includes(search)
      );
    },
    // Add new computed property for region validation
    isValidRegion() {
      return this.formData.region && this.regions.includes(this.formData.region);
    }
  },
  mounted() {
    if (typeof google !== 'undefined') {
      this.isGoogleLoaded = true
      this.initGooglePlaces()
    } else {
      this.loadGooglePlaces()
    }
    // Check if mobile on mount
    this.checkIfMobile();
    // Add resize listener
    window.addEventListener('resize', this.checkIfMobile);

    // Add event listener for address input
    if (this.$refs.addressInput) {
      this.$refs.addressInput.addEventListener('input', this.handleAddressInput);
    }
    this.syncRegionDisplay(); // Sync region display when component mounts
  },
  methods: {
    closeCheckout() {
      this.$emit('close')
    },
    formatPrice(price) {
      if (!price) return '0.00'
      const numPrice = Number(price)
      if (isNaN(numPrice)) return '0.00'
      return numPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    // Update the hash generation to include cart items
    generateOrderHash(orderData) {
      const hashData = {
        products: this.cartItems.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price
        })),
        phone: orderData.phone,
        total: orderData.total,
        address: orderData.address,
        shippingMethod: this.shippingMethod,
        paymentMethod: this.paymentMethod
      };
      
      // Create hash using SHA256 directly
      const hash = SHA256(JSON.stringify(hashData));
      return Base64.stringify(hash);
    },

    // Helper method to get stored order by phone
    getStoredOrderId(phone) {
      const cleanPhone = phone.replace(/\s/g, '');
      const storedOrders = JSON.parse(localStorage.getItem('pendingOrders') || '{}');
      
      if (!storedOrders[cleanPhone]) return null;

      // Generate current order hash
      const currentHash = this.generateOrderHash({
        phone: cleanPhone,
        total: this.finalTotal,
        address: this.formData.address
      });

      // Compare stored hash with current hash
      if (storedOrders[cleanPhone].hash !== currentHash) {
        // If hashes don't match, remove stored order and return null
        this.removeStoredOrder(cleanPhone);
        return null;
      }

      return storedOrders[cleanPhone].orderId;
    },

    // Helper method to store order details
    storeOrderDetails(phone, orderId) {
      const cleanPhone = phone.replace(/\s/g, '');
      const storedOrders = JSON.parse(localStorage.getItem('pendingOrders') || '{}');
      
      // Generate and store hash along with order ID
      const orderHash = this.generateOrderHash({
        phone: cleanPhone,
        total: this.finalTotal,
        address: this.formData.address
      });

      storedOrders[cleanPhone] = {
        orderId: orderId,
        hash: orderHash,
        timestamp: Date.now()
      };

      localStorage.setItem('pendingOrders', JSON.stringify(storedOrders));
      console.log('Stored order ID and hash for phone:', cleanPhone, orderId);
    },

    // Helper method to remove stored order
    removeStoredOrder(phone) {
      const cleanPhone = phone.replace(/\s/g, '');
      const storedOrders = JSON.parse(localStorage.getItem('pendingOrders') || '{}');
      delete storedOrders[cleanPhone];
      localStorage.setItem('pendingOrders', JSON.stringify(storedOrders));
      console.log('Removed stored order for phone:', cleanPhone);
    },

    async submitOrder() {
      if (!this.validateForm() || this.isSubmitting) return;
      
      this.isSubmitting = true;

      try {
        const token = auth.getToken();
        
        if (!token) {
          throw new Error('No auth token available');
        }

        // Check for existing order for this phone number
        const cleanPhone = this.formData.phone.replace(/\s/g, '');
        const existingOrderId = this.getStoredOrderId(cleanPhone);
        
        if (existingOrderId) {
          console.log('Found existing order for phone:', cleanPhone, existingOrderId);
        }

        // Transform cart items to match the required format
        const products = this.cartItems.map(item => ({
          name: item.name,
          product_id: item.id,
          image: item.image,
          variation_names: item.variation_names || '',
          variation_id: item.variation_id || null,
          sku: item.sku || '',
          stock: item.stock || 0,
          taxes: [],
          shipping: {
            shipping_type: 5,
            shipping_cost: "0.000000",
            is_product_quantity_multiply: 10
          },
          quantity: item.quantity,
          discount: 0,
          price: item.price,
          old_price: item.price,
          total_tax: 0,
          subtotal: item.price * item.quantity,
          total: item.price * item.quantity,
          total_price: item.price * item.quantity
        }));

        const orderData = {
          subtotal: this.cartTotal,
          discount: 0,
          tax: 0,
          shipping_charge: this.deliveryCharge, // Use dynamic charge
          total: this.cartTotal + this.deliveryCharge, // Use dynamic charge
          order_type: this.shippingMethod === 'pickup' ? 10 : 5, // Update order type based on delivery selection
          shipping_id: 2,
          billing_id: 2,
          outlet_id: 2,
          coupon_id: 0,
          source: 5,
          payment_method: this.paymentMethod === 'mpesa' ? 1 : 2, // Update payment method
          products: JSON.stringify(products),
          phone: cleanPhone,
          address: this.formData.address+'-'+this.formData.region,
          latitude: this.formData.latitude,
          longitude: this.formData.longitude,
          billing_phone: cleanPhone,
          billing_address: this.formData.address+'-'+this.formData.region,
          shipping_phone: cleanPhone,
          shipping_address: this.formData.address+'-'+this.formData.region,
          order_origin: 'mybeer',
          region: this.formData.region,
        };

        // Add existing order ID if found
        if (existingOrderId) {
          orderData.order_id = existingOrderId;
          console.log('Adding existing order ID to request:', existingOrderId);
        }

        console.log('Sending order data:', orderData);

        const response = await fetch('https://web.mybeer.ke.shop/api/frontend/order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'x-api-key': '' // Add API key if required
          },
          body: JSON.stringify(orderData)
        });

        const result = await response.json();

        // Check both response status and result status
        if (!response.ok) {
          throw new Error(result.message || 'Failed to submit order');
        }

        // Store the new order ID with phone number
        this.storeOrderDetails(cleanPhone, result.data.id);
        
        // Check payment method and show appropriate modal
        if (this.paymentMethod === 'mpesa') {
          // Reset and show M-PESA payment modal
          this.$refs.paymentModal.show({
            orderId: result.data.id,
            orderSerial: result.data.order_serial_no,
            amount: this.finalTotal,
            phone: cleanPhone
          });

          // Start polling for payment status
          this.pollPaymentStatus(result.data.id);
        } else {
      // Clear the cart
         this.$store.commit('CLEAR_CART');
          
          this.$refs.notification.show({
            title: 'Order Placed Successfully',
            message: 'Your order has been placed successfully. We will contact you shortly.',
            type: 'success'
          });
          
          // Close checkout after delay
          setTimeout(() => {
            this.closeCheckout();
          }, 4000);
        }

      } catch (error) {
        console.error('Error submitting order:', error);
        
        // Show error notification
        this.$refs.notification.show({
          title: 'Order Submission Failed',
          message: error.message || 'Failed to submit order. Please try again.',
          type: 'error'
        });

        // Reset submit button state
        this.isSubmitting = false;
        
        // If payment modal is open, update its state
        if (this.$refs.paymentModal) {
          this.$refs.paymentModal.updateModalState({
            status: 'failed',
            message: error.message || 'Order submission failed. Please try again.',
            showClock: false,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="status-icon error" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>`
          });
        }
      }
    },
    setShippingMethod(method) {
      this.shippingMethod = method;
      this.shippingMethod = 'delivery';
      this.paymentMethod = 'mpesa';
      
      // // Set payment method to COD when pickup is selected
      // if (method === 'pickup') {
      //   this.paymentMethod = 'cod';
      //   this.formData.address = '';
      //   this.isAddressValid = true; // Address validation not needed for pickup
      // } else {
      //   // Reset to default payment method (mpesa) for delivery
      //   this.paymentMethod = 'mpesa';
      //   // Initialize Google Places if switching to delivery
      //   if (!this.isGoogleLoaded) {
      //     this.loadGooglePlaces();
      //   }
      // }
    },
    validateNumberInput(event) {
      // Allow only numbers
      const charCode = (event.which) ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault()
      }
    },
    formatPhoneNumber(event) {
      // Remove any non-numeric characters
      let phoneNumber = event.target.value.replace(/\D/g, '')
      
      // Ensure it starts with valid prefixes
      if (phoneNumber.startsWith('254')) {
        phoneNumber = phoneNumber.substring(3)
      }
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.substring(1)
      }
      
      // Limit to 11 digits
      phoneNumber = phoneNumber.substring(0, 9)
      
      // Format the number with spaces
      if (phoneNumber.length > 0) {
        // Format as: 7XX XXX XXXX
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
      }
      
      // Update the input value
      this.formData.phone = phoneNumber
      
      // Validate the phone number
      this.isValidPhone = phoneNumber.replace(/\s/g, '').length === 9
      this.saveFormData(); // Save when phone is updated
    },
    loadGooglePlaces() {
      const interval = setInterval(() => {
        if (typeof google !== 'undefined') {
          clearInterval(interval)
          this.isGoogleLoaded = true
          this.initGooglePlaces()
        }
      }, 100)

      setTimeout(() => clearInterval(interval), 10000)
    },
    initGooglePlaces() {
      if (!this.$refs.addressInput) return

      try {
        // this.autocomplete = new google.maps.places.Autocomplete(this.$refs.addressInput, {
        //   componentRestrictions: { country: 'ke' },
        //   fields: ['formatted_address', 'geometry', 'name'],
        //   types: ['address']
        // })

        // this.autocomplete.addListener('place_changed', () => {
        //   const place = this.autocomplete.getPlace()
          
        //   if (place.geometry) {
        //     this.formData.address = place.formatted_address
        //     this.formData.latitude = place.geometry.location.lat()
        //     this.formData.longitude = place.geometry.location.lng()
        //     this.isAddressValid = true
        //   }
        // })

        // this.$refs.addressInput.addEventListener('keydown', (e) => {
        //   if (e.key === 'Enter') {
        //     e.preventDefault()
        //   }
        // })

      } catch (error) {
        console.error('Error initializing Google Places:', error)
      }
    },
    async printSummary() {
      this.isPrinting = true;
      try {
        const quotationNumber = `QT-${new Date().getTime().toString().slice(-6)}`;
        
        // Updated date formatting
        const now = new Date();
        const currentDate = now.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }).replace(/\//g, '-');
        
        const printWindow = window.open('', '_blank');
        const summaryHTML = `
          <!DOCTYPE html>
          <html>
          <head>
            <title>Quotation - ${quotationNumber}</title>
            <style>
              body {
                font-family: 'Courier New', monospace;
                font-size: 12px;
                line-height: 1.4;
                padding: 20px;
                max-width: 80mm;
                margin: 0 auto;
              }

              .header {
                text-align: center;
                margin-bottom: 20px;
                padding-bottom: 10px;
                border-bottom: 1px dashed #ccc;
              }

              .logo {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 5px;
              }

              .store-info {
                font-size: 12px;
                line-height: 1.4;
              }

              .invoice-details {
                margin: 10px 0;
                padding: 5px 0;
              }

              .invoice-details div {
                display: flex;
                justify-content: space-between;
                margin: 3px 0;
              }

              .items {
                margin: 10px 0;
              }

              .item {
                margin: 5px 0;
                padding-left: 10px;
              }

              .item-name {
                display: block;
                margin-bottom: 2px;
              }

              .item-price-qty {
                padding-left: 15px;
                color: #444;
              }

              .item-total {
                float: right;
              }

              .calculations {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px dashed #ccc;
              }

              .calc-row {
                display: flex;
                justify-content: space-between;
                margin: 3px 0;
              }

              .total-row {
                margin-top: 5px;
                padding-top: 5px;
                border-top: 1px dashed #ccc;
              }

              .footer-section {
                text-align: center;
                margin-top: 20px;
                padding-top: 15px;
                border-top: 1px dashed #ccc;
              }

              .collection-method {
                text-align: center;
                margin: 15px 0;
                font-size: 12px;
              }

              .qr-placeholder {
                text-align: center;
                margin: 15px 0;
                padding: 10px;
                font-size: 12px;
              }

              .thank-you {
                text-align: center;
                margin: 15px 0;
                font-weight: bold;
                font-size: 12px;
              }

              .footer-info {
                text-align: center;
                font-size: 11px;
                color: #666;
                line-height: 1.4;
              }

              .website {
                text-align: center;
                margin-top: 10px;
                font-size: 12px;
                font-weight: bold;
              }

              .logo-container {
                text-align: center;
                margin-bottom: 1rem;
              }
              
              .logo {
                max-width: 120px;
                height: auto;
              }
            </style>
          </head>
          <body>
            <div class="receipt">
              <div class="header">
                <div class="logo-container">
                  <img src="${myBeerLogo}" alt="myBeer Logo" class="logo">
                </div>
                <div class="store-info">
                  <div>Your Bar Supplier</div>
                  <div>Ian Court, Off Ngong Road, Karandini Rd, Nairobi</div>
                  <div>Tel: +254 707 491515</div>
                  <div>info@mybeer.ke</div>
                </div>
              </div>

              <div class="invoice-details">
                <div>
                  <span>Quotation No:</span>
                  <span>${quotationNumber}</span>
                </div>
                <div>
                  <span>Date:</span>
                  <span>${currentDate}</span>
                </div>
                <div>
                  <span>Payment:</span>
                  <span>M-PESA</span>
                </div>
              </div>

              <div class="items">
                ${this.cartItems.map(item => `
                  <div class="item">
                    <span class="item-name">${item.name}</span>
                    <div class="item-price-qty">
                      ${item.quantity}X KSH ${this.formatPrice(item.price)}
                      <span class="item-total">KSH ${this.formatPrice(item.price * item.quantity)}</span>
                    </div>
                  </div>
                `).join('')}
              </div>

              <div class="calculations">
                <div class="calc-row">
                  <span>Subtotal:</span>
                  <span>KSH ${this.formatPrice(this.subtotalBeforeVat)}</span>
                </div>
                <div class="calc-row">
                  <span>VAT (16%):</span>
                  <span>KSH ${this.formatPrice(this.vatAmount)}</span>
                </div>
                ${this.shippingMethod === 'delivery' ? `
                  <div class="calc-row">
                    <span>Delivery Fee:</span>
                    <span>KSH ${this.formatPrice(this.deliveryCharge)}</span>
                  </div>
                ` : ''}
                <div class="calc-row total-row">
                  <span>Total Amount:</span>
                  <span>KSH ${this.formatPrice(this.finalTotal)}</span>
                </div>
              </div>

              <!-- Add shipping method info -->
              <div class="shipping-info" style="margin-top: 1rem; padding-top: 1rem; ">
              
                ${this.shippingMethod === 'pickup' ? `
                  <div style="margin-top: 0.5rem; font-size: 0.85rem; color: #6b7280;">
                    Pickup Location: Two Buddies Liquor Store<br>
                    123 Main Street, Nairobi
                  </div>
                ` : ''}
              </div>
            
              <!-- Footer Section -->
              <div class="footer-section">
                <div class="collection-method">
                  Collection Method: ${this.shippingMethod === 'delivery' ? 'Delivery' : 'Store Pickup'}
                </div>


                <div class="thank-you">
                  Thank you for shopping with myBeerKE!
                </div>

                <div class="footer-info">  
                  Terms & Conditions Apply
                </div>

                <div class="website">
                  www.mybeer.ke
                </div>
              </div>
            </div>
          </body>
          </html>
        `;
        
        printWindow.document.write(summaryHTML);
        printWindow.document.close();
        
        printWindow.onload = () => {
          printWindow.print();
          setTimeout(() => {
            this.isPrinting = false;
            // Optional: Add success state handling
          }, 1000);
        };
      } catch (error) {
        console.error('Print error:', error);
        this.isPrinting = false;
      }
    },
    async pollPaymentStatus(orderId) {
      const pollInterval = setInterval(async () => {
        try {
          const response = await fetch(`https://web.mybeer.ke/api/frontend/payment/status/${orderId}`, {
            headers: {
              'Authorization': `Bearer ${auth.getToken()}`,
              'Content-Type': 'application/json',
              'x-api-key': ''
            }
          });
          
          const result = await response.json();
          
          if (!result.success) {
            throw new Error(result.message || 'Failed to check payment status');
          }

          switch (result.status) {
            case 'SUCCESS':
              clearInterval(pollInterval);
              // Update payment modal with success state
              this.$refs.paymentModal.updateModalState({
                status: 'success',
                message: 'Payment successful! Your order has been confirmed.',
                showClock: false,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" class="status-icon success" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/>
                    <path d="M22 4L12 14.01l-3-3"/>
                  </svg>`
              });
              
              // Show success notification
              this.$refs.notification.show({
                title: 'Payment Successful',
                message: 'Your payment has been processed successfully.',
                type: 'success'
              });

              // Clear the cart
              this.$store.commit('CLEAR_CART');
              
              // Wait for 2 seconds before reloading
              setTimeout(() => {
                window.location.reload();
              }, 4000);
              break;

            case 'FAILED':
              // Clear cart using Vuex store action
      
              clearInterval(pollInterval);
              this.$refs.paymentModal.updateModalState({
                status: 'failed',
                message: result.message || 'Payment failed. Please try again.',
                showClock: false,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" class="status-icon error" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>`
              });
              break;

            case 'PENDING':
          
              // Show spinning icon for pending state
              this.$refs.paymentModal.updateModalState({
                status: 'pending',
                message: 'Processing payment...',
                showClock: true
              });
              break;

            default:
              console.log('Payment status:', result.status);
              break;
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          clearInterval(pollInterval);
          
          // Update modal to show error state
          this.$refs.paymentModal.updateModalState({
            status: 'error',
            message: error.message || 'An error occurred while checking payment status.',
            showClock: false,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="modal-icon error" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>`
          });
          
          this.$refs.notification.show({
            title: 'Error',
            message: error.message || 'Failed to check payment status',
            type: 'error'
          });
        }
      }, 5000);

      // Clean up interval after 45 seconds
      setTimeout(() => {
        clearInterval(pollInterval);
        if (this.$refs.paymentModal && this.$refs.paymentModal.$el.offsetParent !== null) {
          // Update modal to show timeout state
          this.$refs.paymentModal.updateModalState({
            status: 'timeout',
            message: 'Payment status check timed out. Please check your M-PESA for the status.',
            showClock: false,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="modal-icon warning" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>`
          });
          
          this.$refs.notification.show({
            title: 'Payment Timeout',
            message: 'Payment status check timed out. Please check your M-PESA for the status.',
            type: 'warning'
          });
        }
      }, 45000);
    },
    handlePaymentModalClose() {
      // Remove stored order when modal is closed
      if (this.formData.phone) {
        //this.removeStoredOrder(this.formData.phone);
      }
    },
    handlePaymentTimeout() {
      // Don't reset order ID on timeout as we might want to retry
      console.log('Payment timeout for order:', {
        id: this.currentOrderId,
        serial: this.currentOrderSerial
      });
    },
  
    // Update reset method to clear both IDs
    resetOrder() {
      console.log('Resetting order state');
      this.currentOrderId = null;
      this.currentOrderSerial = null;
    },
    handlePaymentSuccess() {
      // Remove stored order on successful payment
      if (this.formData.phone) {
        this.removeStoredOrder(this.formData.phone);
      }
      
      // Clear the cart
      this.$emit('clear-cart');
      
      // Show success notification
      this.$refs.notification.show({
        title: 'Payment Successful',
        message: 'Your order has been confirmed and will be processed shortly.',
        type: 'success'
      });
      
      // Close checkout
      setTimeout(() => {
        this.closeCheckout();
      }, 2000);
    },


    // Store order details with hash
    saveOrderToStorage(orderHash, orderId) {
      const orderStorage = {
        orderId,
        hash: orderHash,
        timestamp: Date.now()
      };
      localStorage.setItem('pendingOrder', JSON.stringify(orderStorage));
      this.lastOrderHash = orderHash;
      console.log('Stored order:', orderStorage);
    },

    // Get stored order if hash matches
    getStoredOrder(currentHash) {
      try {
        const stored = localStorage.getItem('pendingOrder');
        if (!stored) return null;

        const orderStorage = JSON.parse(stored);
        
        // Check if hash matches and order is not too old (e.g., 1 hour)
        if (orderStorage.hash === currentHash && 
            Date.now() - orderStorage.timestamp < 3600000) {
          return orderStorage.orderId;
        }
        
        // Clear invalid/old storage
        this.clearStoredOrder();
        return null;
      } catch (error) {
        console.error('Error reading stored order:', error);
        return null;
      }
    },

    // Clear stored order
    clearStoredOrder() {
      localStorage.removeItem('pendingOrder');
      this.lastOrderHash = null;
      console.log('Cleared stored order');
    },

    // Add this method to handle delivery type change
    handleDeliveryTypeChange(type) {
      this.deliveryCharge = type === 'pickup' ? 0 : 100;
      console.log('Delivery charge updated:', this.deliveryCharge);
    },
    handleDeliveryMethodChange(isPickup) {
      this.isPickup = isPickup;
      console.log('Delivery method changed:', isPickup ? 'Pickup' : 'Delivery');
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1000;
    },
    setPaymentMethod(method) {
      // Only allow changing payment method if not pickup
      if (this.shippingMethod !== 'pickup') {
        this.paymentMethod = method;
      }
    },
    filterRegions() {
      this.showRegionDropdown = true;
    },
    selectRegion(region) {
      this.formData.region = region;
      this.regionSearch = region; // Keep regionSearch in sync
      this.showRegionDropdown = false;
      this.formErrors.region = ''; // Clear error when valid region is selected
      this.saveFormData(); // Save when region is selected
    },
    getStoredFormData() {
      try {
        const storedData = localStorage.getItem('checkoutFormData');
        return storedData ? JSON.parse(storedData) : null;
      } catch (error) {
        console.error('Error reading from localStorage:', error);
        return null;
      }
    },
    saveFormData() {
      try {
        localStorage.setItem('checkoutFormData', JSON.stringify(this.formData));
      } catch (error) {
        console.error('Error saving to localStorage:', error);
      }
    },
    handleAddressInput() {
      this.saveFormData(); // Save when address is updated
    },
    clearFormData() {
      this.formData = {
        phone: '',
        address: '',
        latitude: null,
        longitude: null,
        deliveryType: 'delivery',
        region: ''
      };
      localStorage.removeItem('checkoutFormData');
    },
    // Add this method to sync region display when form data changes
    syncRegionDisplay() {
      if (this.formData.region) {
        this.regionSearch = this.formData.region;
      }
    },
    validateForm() {
      if (this.shippingMethod === 'delivery') {
        if (!this.isValidRegion) {
          this.formErrors.region = 'Please select a valid delivery region';
          return false;
        }
        
        if (!this.isValidPhone || !this.formData.address) {
          return false;
        }
      } else {
        if (!this.isValidPhone) {
          return false;
        }
      }
      
      this.formErrors.region = ''; // Clear error when valid
      return true;
    },
  },
  watch: {
    'formData': {
      handler() {
        this.saveFormData();
      },
      deep: true
    },
    'formData.region': {
      handler(newRegion) {
        this.regionSearch = newRegion; // Keep regionSearch in sync with formData.region
      },
      immediate: true
    }
  },
  async created() {
    try {
      // Check if we're logged in
      if (!auth.isLoggedIn()) {
        // If not, attempt to login
        const token = await auth.login();
        if (!token) {
          console.error('Failed to obtain auth token');
        }
      }
      
      // Initialize Google Places
      this.loadGooglePlaces();
    } catch (error) {
      console.error('Error during component creation:', error);
    }
  },
  beforeUnmount() {
    // Clean up resize listener
    window.removeEventListener('resize', this.checkIfMobile);

    // Remove event listener
    if (this.$refs.addressInput) {
      this.$refs.addressInput.removeEventListener('input', this.handleAddressInput);
    }
  }
}
</script>

<style scoped>
.checkout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 998;
}

.checkout-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100vh;
  background: white;
  z-index: 999;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  box-shadow: -4px 0 25px rgba(0, 0, 0, 0.15);
}

.panel-open {
  transform: translateX(0);
}

.checkout-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.checkout-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background: #f1f5f9;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close-btn svg {
  width: 20px;
  height: 20px;
  color: #64748b;
}

.close-btn:hover {
  background: #e2e8f0;
  transform: rotate(90deg);
}

.checkout-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

.order-summary {
  max-width: 500px;
  margin: 0 auto;
}

.order-summary h3 {
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
  color: #1e293b;
}

.items-count {
  color: #64748b;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.total-amount {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  font-family: var(--font-mono);
}

.checkout-sections {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px); /* Adjust based on header height */
  display: flex;
}

.form-section {
  flex: 2;
  padding: 2rem;
  overflow-y: auto;
}

.summary-section {
  flex: 1;
  padding: 2rem;
  background: #f8fafc;
  overflow-y: auto;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.75rem;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1e293b;
  font-size: 0.9375rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.address-input {
  width: 100%;
  height: auto; /* Fixed height for input */
  padding: 0.75rem 2.5rem 0.75rem 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
  background: #f8fafc;
}

.address-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background: white;
}

.address-icon {
  position: absolute;
  right: 0.75rem;
  color: #94a3b8;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
}

.address-icon svg {
  width: 100%;
  height: 100%;
}

.address-icon {
  position: absolute;
  right: 0.75rem;
  color: #94a3b8;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
}

.address-icon svg {
  width: 100%;
  height: 100%;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  font-size: 0.875rem;
  color: #64748b;
}

.summary-item span:first-child {
  flex: 1;
  padding-right: 1rem;
}

.item-price {
  white-space: nowrap;
}

.summary-total {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 2px solid #e2e8f0;
}

.total-row {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #1e293b;
  font-size: 1.125rem;
}

.submit-order-btn {
  width: 100%;
  margin-top: 1.5rem;
  padding: 1rem;
  background: #16a34a;
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-order-btn:hover:not(:disabled) {
  background: #15803d;
}

.submit-order-btn:disabled {
  background: #94a3b8;
  cursor: not-allowed;
}

.summary-calculations {
  margin-top: 2rem;
  padding-top: 1.5rem;
}

.calc-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 0;
  font-size: 0.9375rem;
}

.calc-row .amount {
  font-family: var(--font-mono);
  color: #1e293b;
  font-weight: 500;
}

.calc-row.discount {
  color: #16a34a;
}

.calc-row.discount .amount {
  color: #16a34a;
  font-weight: 500;
}

.calc-row.total {
  margin-top: 1rem;
  padding-top: 1.25rem;
  border-top: 2px solid #e2e8f0;
  font-weight: 600;
  color: #1e293b;
  font-size: 1.125rem;
}

.calc-row.total .amount {
  font-size: 1.25rem;
  font-weight: 700;
}

.cart-items {
  overflow-y: auto;
  margin-bottom: 1.5rem;
  padding-right: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.cart-items::-webkit-scrollbar {
  width: 4px;
}

.cart-items::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 2px;
}

.cart-items::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  font-size: 0.875rem;
  color: #64748b;
}

.summary-item:last-child {
  border-bottom: none;
}

.shipping-toggle {
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.shipping-toggle h4 {
  margin-bottom: 1rem;
  color: #1e293b;
  font-size: 1rem;
  font-weight: 500;
}

.toggle-buttons {
  display: flex;
  gap: 1rem;
}

.toggle-btn {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: inherit;
}

.toggle-btn.active {
  border-color: #3b82f6;
  background: #eff6ff;
}

.toggle-icon {
  width: 24px;
  height: 24px;
  color: #64748b;
}

.toggle-btn.active .toggle-icon {
  color: #3b82f6;
}

.toggle-content {
  text-align: left;
  font-family: inherit;
}

.toggle-title {
  display: block;
  font-weight: 500;
  color: #1e293b;
  font-family: inherit;
}

.toggle-desc {
  display: block;
  font-size: 0.875rem;
  color: #64748b;
  font-family: inherit;
}

.pickup-info {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
  margin-top: 1rem;
}

.store-details {
  color: #64748b;
  font-size: 0.875rem;
  line-height: 1.5;
}

.store-name {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Mobile responsiveness */
@media (max-width: 1280px) {
  .form-section {
    flex: 1.5;
  }
  
  .summary-section {
    flex: 1;
  }
}

@media (max-width: 1024px) {
  .checkout-sections {
    flex-direction: column;
  }

  .form-section,
  .summary-section {
    flex: none;
  }

  .form-section {
    border-right: none;
    border-bottom: 1px solid #e2e8f0;
  }

  .order-summary {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .form-section,
  .summary-section {
    padding: 1rem;
  }

  .calc-row {
    padding: 0.75rem 0;
    font-size: 0.875rem;
  }

  .calc-row.total {
    font-size: 1rem;
  }

  .calc-row.total .amount {
    font-size: 1.125rem;
  }
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
}

.form-card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  border: 1px solid #e2e8f0;
  overflow: hidden;
}

.card-header {
  padding: 1.25rem;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.card-header h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
}

.card-body {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.form-input::placeholder {
  color: #94a3b8;
}

.store-details {
  color: #64748b;
  font-size: 0.9375rem;
  line-height: 1.6;
}

.store-name {
  color: #1e293b;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.store-address,
.store-hours {
  margin-bottom: 0.25rem;
}

/* Update toggle buttons styling to match card design */
.toggle-buttons {
  display: flex;
  gap: 1rem;
}

.toggle-btn {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: inherit;
}

.toggle-btn.active {
  border-color: #3b82f6;
  background: #eff6ff;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .form-card {
    margin-bottom: 1rem;
    border-radius: 0.75rem;
  }

  .card-header {
    padding: 1rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .toggle-buttons {
    flex-direction: column;
  }

  .form-input {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
}

/* Optional: Add hover effects */
.form-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.toggle-btn:hover:not(.active) {
  background: #f8fafc;
  border-color: #cbd5e1;
}


.customer-details-card .card-body {
  padding: 1.5rem; /* Additional right padding in card body */
}

.form-content {
  max-width: 96%; /* Limit the width of the form content */
}

/* Update form inputs for better spacing */
.form-group {
  margin-bottom: 1.75rem;
  position: relative;
}

.form-input {
  width: 100%;
  padding: 0.875rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
  background: #f8fafc;
}

.address-input {
  min-height: 15px;
  resize: vertical;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .customer-details-card {
    padding-right: 1.5rem;
  }

  .customer-details-card .card-body {
    padding: 1.5rem;
  }

  .form-content {
    max-width: 95%;
  }
}

@media (max-width: 768px) {
  .customer-details-card {
    padding-right: 1rem;
  }

  .customer-details-card .card-body {
    padding: 1.25rem;
  }

  .form-content {
    max-width: 100%;
  }

  .form-input {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}

/* Optional: Add smooth transitions */
.form-card {
  transition: all 0.3s ease;
}

.form-input {
  transition: all 0.2s ease;
}

/* Optional: Enhance focus states */
.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background: white;
}

/* Optional: Add hover state */
.form-input:hover {
  border-color: #94a3b8;
}

.phone-input-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.phone-input-container:focus-within {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background: white;
}

.phone-prefix {
  padding: 0.875rem 0.5rem 0.875rem 1rem;
  color: #64748b;
  font-size: 0.9375rem;
  font-family: monospace;
  border-right: 1px solid #e2e8f0;
  background: #f1f5f9;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.phone-input {
  border: none !important;
  background: transparent !important;
  padding-left: 0.75rem !important;
  font-family: monospace;
  letter-spacing: 0.5px;
}

.phone-input:focus {
  box-shadow: none !important;
}

.input-hint {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.8125rem;
  color: #64748b;
}

.input-hint.error {
  color: #ef4444;
}

.input-hint:not(.error) {
  color: #16a34a;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .phone-prefix {
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;
  }

  .phone-input {
    padding: 0.75rem !important;
    padding-left: 0.5rem !important;
    font-size: 0.875rem;
  }

  .input-hint {
    font-size: 0.75rem;
  }
}

/* Optional: Add validation visual feedback */
.phone-input-container.invalid {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.phone-input::placeholder {
  letter-spacing: 1px;
  color: #94a3b8;
}

.address-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.address-input {
  padding-right: 2.5rem !important;
}

.address-icon {
  position: absolute;
  right: 0.75rem;
  color: #94a3b8;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
}

.address-icon svg {
  width: 100%;
  height: 100%;
}

/* Google Places Autocomplete customization */
::v-deep .pac-container {
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-top: 4px;
  font-family: inherit;
  z-index: 1000;
}

::v-deep .pac-item {
  padding: 0.75rem;
  font-size: 0.9375rem;
  cursor: pointer;
}

::v-deep .pac-item:hover {
  background-color: #f8fafc;
}

::v-deep .pac-item-query {
  font-size: 0.9375rem;
  color: #1e293b;
}

::v-deep .pac-matched {
  color: #3b82f6;
  font-weight: 600;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .address-input {
    height: 44px;
    padding: 0.625rem 2.25rem 0.625rem 0.75rem !important;
    font-size: 0.875rem;
  }

  .address-icon {
    right: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  :deep(.pac-container) {
    width: calc(100% - 2rem) !important;
    left: 1rem !important;
  }

  :deep(.pac-item) {
    padding: 0.625rem;
    font-size: 0.875rem;
  }

  :deep(.pac-item-query) {
    font-size: 0.875rem;
  }
}

/* Optional: Add loading state */
.address-input-container.is-loading .address-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Make sure the autocomplete suggestions appear above everything */
:deep(.pac-container) {
  z-index: 9999;
  background-color: white;
  position: fixed;
  width: auto !important;
  min-width: 300px;
}

.address-input-container {
  position: relative;
}

.address-input {
  width: 100%;
}

/* Ensure the input doesn't have browser autocomplete styling */
.address-input:-webkit-autofill,
.address-input:-webkit-autofill:hover,
.address-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/* Adjust spacing now that we removed the name input */
.form-group:first-child {
  margin-top: 0;
}

.form-group:last-child {
  margin-bottom: 0;
}

.card-body {
  padding: 1.5rem;
}

/* Optional: Add more vertical spacing between remaining fields */
.form-group {
  margin-bottom: 2rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.complete-order-section {
  margin-top: 1.5rem;
  padding: 0 1rem;
}

.submit-order-btn {
  width: 100%;
  padding: 1rem 1.5rem;
  background: #16a34a;
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-order-btn:hover:not(:disabled) {
  background: #15803d;
}

.submit-order-btn:disabled {
  background: #94a3b8;
  cursor: not-allowed;
}

/* Optional: Add loading state */
.submit-order-btn.loading {
  position: relative;
  color: transparent;
}

.submit-order-btn.loading::after {
  content: '';
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .complete-order-section {
    margin-top: 1rem;
    padding: 0 0.75rem;
  }

  .submit-order-btn {
    padding: 0.875rem 1.25rem;
    font-size: 0.9375rem;
  }
}

/* Optional: Add sticky behavior for mobile */
@media (max-width: 1024px) {
  .complete-order-section {
    position: sticky;
    bottom: 1rem;
    background: white;
    padding: 1rem;
    margin: 0 -1rem;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .submit-order-btn {
    max-width: 600px;
    margin: 0 auto;
  }
}

.phone-input {
  letter-spacing: 0.5px;
  font-family: monospace;
}

.phone-prefix {
  padding: 0.875rem 0.5rem 0.875rem 1rem;
  color: #64748b;
  font-size: 0.9375rem;
  font-family: monospace;
  border-right: 1px solid #e2e8f0;
  background: #f1f5f9;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.phone-input::placeholder {
  letter-spacing: 1px;
}

.phone-input-container.valid .phone-input {
  border-color: #16a34a;
  background-color: #f0fdf4;
}

.phone-input-container.valid .phone-prefix {
  border-color: #16a34a;
  color: #16a34a;
}

@media (max-width: 768px) {
  .phone-prefix {
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .phone-input::placeholder {
    font-size: 0.875rem;
  }
}

.item-number {
  font-weight: 600;
  color: #1e293b; /* Darker color for better contrast */
  margin-right: 4px;
}

/* Existing styles */
.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  font-size: 0.875rem;
  color: #64748b;
}

.summary-item span:first-child {
  flex: 1;
  padding-right: 1rem;
}

.item-price {
  white-space: nowrap;
}

.summary-title {
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
  border-bottom: 0.9px solid #e2e8f0; /* Thinner light gray underline */
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

/* Optional: Add hover effect */
.summary-title:hover {
  border-bottom-color: #94a3b8; /* Darker gray on hover */
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.print-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: auto; /* Changed from 100% to auto */
  margin-top: 1.5rem;
  margin-left: auto; /* Align to right */
  padding: 0.5rem 1rem; /* Reduced padding */
  background: #3b82f6; /* Nice blue color */
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

/* Hover effect with scale and shadow */
.print-btn:hover {
  transform: translateY(-2px) scale(1.02);
  background: #2563eb; /* Slightly darker blue on hover */
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

/* Active state */
.print-btn:active {
  transform: translateY(0) scale(0.98);
  box-shadow: 0 2px 6px rgba(59, 130, 246, 0.2);
}

/* Print icon styling */
.print-icon {
  width: 1rem; /* Smaller icon */
  height: 1rem;
  transition: all 0.3s ease;
}

/* Ripple effect on click */
.print-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.print-btn:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* Icon rotation animation on hover */
.print-btn:hover .print-icon {
  transform: rotate(-10deg);
}

/* Optional: Add loading state */
.print-btn.loading {
  background: #94a3b8;
  cursor: not-allowed;
}

.print-btn.loading .print-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Optional: Add success state */
.print-btn.success {
  background: #10b981;
}

/* Media query for mobile */
@media (max-width: 768px) {
  .print-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .print-icon {
    width: 0.9rem;
    height: 0.9rem;
  }
}

.complete-order-btn {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  min-height: 3.5rem; /* Fixed height to prevent jumping */
}

/* Valid state - Green */
.complete-order-btn.is-valid {
  background: #10b981;
  color: white;
}

/* Invalid state - Grey */
.complete-order-btn.is-invalid {
  background: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
}

/* Button content transitions */
.button-content {
  transition: all 0.3s ease;
  opacity: 1;
  position: absolute;
}

.button-content.fade-out {
  opacity: 0;
  transform: scale(0.8);
}

/* Loading spinner */
.loading-spinner {
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;
}

.loading-spinner.fade-in {
  opacity: 1;
}

/* Circular spinner animation */
.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.path {
  stroke: #ffffff;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

/* Button states */
.complete-order-btn.is-valid:hover:not(:disabled) {
  background: #059669;
}

.complete-order-btn:disabled {
  cursor: not-allowed;
}

.complete-order-btn.is-loading {
  background: #10b981 !important;
  cursor: wait;
}

/* Optional: Add focus state for accessibility */
.complete-order-btn:focus {
  outline: none;
  ring: 2px solid #10b981;
  ring-offset: 2px;
}

.order-card {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 1.5rem 0;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.card-header {
  background: #f8fafc;
  padding: 1.25rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h3 {
  color: #1f2937;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.items-count {
  background: #eef2ff;
  color: #4f46e5;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.card-body {
  padding: 1.5rem;
}

.summary-items {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.summary-item:hover {
  background: #f9fafb;
}

.item-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.item-image {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.item-name {
  color: #374151;
  font-weight: 500;
  font-size: 0.95rem;
}

.item-quantity {
  color: #6b7280;
  font-size: 0.875rem;
}

.item-price {
  font-weight: 600;
  color: #374151;
  font-family: monospace;
  letter-spacing: 0.5px;
  font-size: 0.95rem;
}

.summary-divider {
  height: 1px;
  background: #e5e7eb;
  margin: 1.5rem 0;
}

.summary-calculations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calc-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4b5563;
  font-size: 0.95rem;
}

.calc-row span:last-child {
  font-family: monospace;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.calc-row.total {
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 2px solid #e5e7eb;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
}

.calc-row.total span:last-child {
  color: #4f46e5;
}

/* Responsive styles */
@media (max-width: 640px) {
  .order-card {
    margin: 1rem 0;
    border-radius: 12px;
  }

  .card-header {
    padding: 1rem;
  }

  .card-body {
    padding: 1rem;
  }

  .item-image {
    width: 40px;
    height: 40px;
  }

  .item-name {
    font-size: 0.875rem;
  }

  .calc-row.total {
    font-size: 1rem;
  }
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.summary-item {
  animation: slideIn 0.3s ease;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.print-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.print-button:hover {
  background: #f9fafb;
  border-color: #d1d5db;
  color: #374151;
}

.print-button:active {
  background: #f3f4f6;
}

.print-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Update existing card-header style */
.card-header {
  background: #f8fafc;
  padding: 1.25rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .print-button {
    padding: 0.375rem 0.75rem;
    font-size: 0.813rem;
  }

  .print-icon {
    width: 1rem;
    height: 1rem;
  }
}

/* Update existing styles */
.summary-calculations {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Reduced from 1rem */
  margin-top: 0.75rem; /* Reduced from 1.5rem */
}

.calc-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4b5563;
  font-size: 0.95rem;
  padding: 0.25rem 0; /* Reduced padding */
}

.calc-row.total {
  margin-top: 0.5rem; /* Reduced from 1rem */
  padding-top: 0.5rem; /* Reduced from 1rem */
  border-top: 1px solid #e5e7eb; /* Made border thinner */
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
}

.summary-divider {
  height: 1px;
  background: #e5e7eb;
  margin: 0.75rem 0; /* Reduced from 1.5rem */
}

/* Optional: Update item spacing if needed */
.summary-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* Reduced from 1.25rem */
}

.summary-item {
  padding: 0.25rem 0; /* Reduced padding */
}

/* Optional: Add specific styling for the VAT row */
.calc-row.vat {
  color: #6B7280; /* Slightly muted color for tax */
  font-size: 0.9rem;
}

.calc-row.vat span:first-child {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.item-info {
  flex: 1;
  padding-right: 1rem;
}

.item-name {
  display: block;
  margin-bottom: 0.25rem;
  color: #1f2937;
}

.item-price-qty {
  font-size: 0.9em;
  color: #6B7280;
}

.item-total {
  font-weight: 500;
  color: #1f2937;
}

/* Add media query for mobile screens */
@media (max-width: 768px) {
  .checkout-panel {
    width: 100%;  /* Full width on mobile */
    right: 0;
    left: 0;
  }

  /* Optional: Adjust padding for better mobile layout */
  .form-section,
  .summary-section {
    padding: 1rem;
  }
}

/* Add these new styles */
.mobile-summary {
  position: sticky;
  top: 0;
  background: white;
  z-index: 9;
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.summary-card {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #64748b;
  font-size: 0.875rem;
}

.summary-row.total {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
  font-weight: 600;
  color: #1e293b;
  font-size: 1rem;
}

/* Show mobile summary only on mobile screens */
@media (max-width: 1000px) {
  .mobile-summary {
    display: block;
  }
  
  /* Adjust main content padding to account for summary card */
  .checkout-sections {
    padding-top: 0;
  }
}

/* Optional: Add smooth scrolling */
.checkout-sections {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

/* Optional: Hide scrollbar but keep functionality */
.checkout-sections::-webkit-scrollbar {
  width: 6px;
}

.checkout-sections::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.checkout-sections::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.payment-method-card .toggle-buttons {
  display: flex;
  gap: 1rem;
}

.payment-method-card .toggle-btn {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.payment-method-card .toggle-btn.active {
  border-color: #3b82f6;
  background: #eff6ff;
}

.payment-method-card .toggle-icon {
  width: 24px;
  height: 24px;
  color: #64748b;
}

.payment-method-card .toggle-btn.active .toggle-icon {
  color: #3b82f6;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .payment-method-card .toggle-buttons {
    flex-direction: column;
  }

  .payment-method-card .toggle-btn {
    padding: 1rem;
  }
}

.select-container {
  position: relative;
}

.region-input {
  width: 90%;
  padding-right: 2.5rem;
  cursor: pointer;
}

.select-dropdown {
  position: absolute;
  bottom: 100%; /* Changed from top: 100% to bottom: 100% */
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem; /* Changed from margin-top to margin-bottom */
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1); /* Adjusted shadow direction */
  z-index: 50;
}

/* Add a scrollbar styling for better UX */
.select-dropdown::-webkit-scrollbar {
  width: 8px;
}

.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.select-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.select-option:hover {
  background: #f8fafc;
}

.select-option.selected {
  background: #eff6ff;
  color: #3b82f6;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .select-dropdown {
    max-height: 180px;
  }
  
  .select-option {
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
  }
}
</style> 